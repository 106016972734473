export const resources_loading_toasts = {
  //=======================================================
  //EN
  //=======================================================
  en: {
    translation: {
      toast: {
        default: {
          success: {
            title: 'Success',
            desc: 'Thank you for your patience'
          },
          loading: {
            title: 'Processing',
            desc: 'Please wait'
          },
          error: {
            title: 'Processing failed',
            desc: 'Please try again or contact contact@terriflux.fr if the problem persists'
          }
        },
        reset: {
          success: {
            title: 'Drawing area cleaned',
            desc: ''
          },
          loading: {
            title: 'Deleting drawing area',
            desc: ''
          },
        },
        draw: {
          success: {
            title: 'Ready to draw',
            desc: ''
          },
          loading: {
            title: 'Initialisation of drawing area',
            desc: ''
          },
        },
        save_in_cache: {
          success: {
            title: 'Finished',
          },
          loading: {
            title: 'Saving in cache',
          },
          error: {
            title: 'Error while trying to save',
          }
        },
        save_as_json: {
          success: {
            title: 'Download will start shortly',
          },
          loading: {
            title: 'Preparing JSON file',
          },
          error: {
            title: 'Error while trying prepare file',
          }
        },
        save_as_excel: {
          success: {
            title: 'Download will start shortly',
          },
          loading: {
            title: 'Preparing Excel file',
          },
          error: {
            title: 'Error while trying prepare file',
          }
        },
        save_as_png: {
          success: {
            title: 'Download will start shortly',
          },
          loading: {
            title: 'Preparing PNG file',
          },
          error: {
            title: 'Error while trying prepare file',
          }
        },
        set_view: {
          success: {
            title: 'Ready',
          },
          loading: {
            title: 'Switching view',
          },
          error: {
            title: 'Error while loading the requested view',
          }
        },
        compute_auto_sankey: {
          success: {
            title: 'Nodes positions computed',
          },
          loading: {
            title: 'Computing nodes positions',
          },
        }
      },
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      toast: {
        default: {
          success: {
            title: 'Terminé',
            desc: 'Merci de votre patience'
          },
          loading: {
            title: 'Traitement en cours',
            desc: 'Veuillez patienter'
          },
          error: {
            title: 'Echec lors du traitement',
            desc: 'Veuillez rééssayer ou contactez contact@terriflux.fr si le problème persiste'
          }
        },
        reset: {
          success: {
            title: 'Effacement terminé',
            desc: ''
          },
          loading: {
            title: 'Effacement des données',
            desc: ''
          },
        },
        draw: {
          success: {
            title: 'La zone de dessin est prête',
            desc: ''
          },
          loading: {
            title: 'Initialisation de la zone de dessin',
            desc: ''
          },
        },
        save_in_cache: {
          success: {
            title: 'Terminé',
          },
          loading: {
            title: 'Sauvegarde en cache',
          },
          error: {
            title: 'Echec lors de la sauvegarde',
          }
        },
        save_as_json: {
          success: {
            title: 'Le téléchargement va démarrer...',
          },
          loading: {
            title: 'Conversion en JSON',
          },
          error: {
            title: 'Erreur lors de la conversion',
          }
        },
        save_as_excel: {
          success: {
            title: 'Le téléchargement va démarrer...',
          },
          loading: {
            title: 'Conversion en Excel',
          },
          error: {
            title: 'Erreur lors de la conversion',
          }
        },
        save_as_png: {
          success: {
            title: 'Le téléchargement va démarrer...',
          },
          loading: {
            title: 'Conversion en PNG',
          },
          error: {
            title: 'Erreur lors de la conversion',
          }
        },
        set_view: {
          success: {
            title: 'Vue prête',
          },
          loading: {
            title: 'Changement de vue',
          },
          error: {
            title: 'Erreur lors de la chargement de la vue demandée',
          }
        },
        compute_auto_sankey: {
          success: {
            title: 'Noeuds positionnés',
          },
          loading: {
            title: 'Recalcul des positions des noeuds',
          },
        },
      },
    }
  }
}

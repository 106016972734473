
// THE TRANSLATIONS
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources_app_elements = {
  //=======================================================
  //EN
  //=======================================================
  en: {
    translation: {
      'connect': 'LogIn',
      MEP: {
        onValidate: 'Don\'t forget to load the file',
        load_icon: 'Load an icon library (from icomoon)',
        'onBlurNoEnter': 'Quit editor to update the data',
        show_image: 'Show image',
      },
      Menu: {
        'LL': 'Text Area / Image Area',
        'view': 'View',
        'unit': 'Unitary Sankey',
        'afm': 'AFM',
        'afm_tools': 'Tools',
        'excel': 'Extract data',
        'pub': 'Publish',
        'setResolutionPNG': 'Select the desired export resolution',
        'sankeyOSPDisabled': 'Parameter disabled because you don\'t have OpenSankey+',
        'featureLocked': 'Locked',
        'featureBeta': 'Beta-test',

        'home': 'Master',
        'addView': 'Add',
        'updateView': 'Update',
        'precView': 'Prec.',
        'nextView': 'Next',

        'toBeautify': 'Beautify JSON file',
        'updateFOZdd': 'Update Foreign object on drawing area',

        'import_icon': 'Select icon',
        'import_icon_from_pack': 'Import icons from pack',
        'filter_by_name': 'Filter by name ',

        'presentation_OS': 'OpenSankey is a web application that makes it easy to create Sankey diagrams.\n\n It\'s available free of charge, and includes simple functions for creating nodes, flows and labeling them to aggregate or filter their display on the diagram.\n\n The 15-node limit for use without an account can be lifted by creating a license-free account.\n\n',
        'presentation_OS_limit_node': 'The 15-node limit for use without an account can be lifted by creating a free account.\n\n',
        'presentation_OSP': 'OpenSankey+ is a paid license that unlocks new features for creating beautiful Sankey diagrams for presentations.\n\nThis license includes advanced formatting features such as the addition of illustrative images or icons for nodes, the addition of color gradients on flows and animations, and more.\n\n But above all, OpenSankey+ lets you generate slideshows to explain your Sankey diagrams, thanks to the "Views" mechanism, which records their visual states (with / without certain filters activated, for example) to simplify navigation between them.\n\n',
        'presentation_OSS': 'SankeySuite is a paid license that unlocks all the advanced charting and analysis features of Sankey.\n\n This license includes flow data reconciliation functionalities to identify inconsistencies or calculate flows whose values are not directly accessible.\n\n It also includes OpenSankey+ functionalities.\n\n',
        afm_reconcil_json: 'Reconciling actual sankey diagram',
        afm_reconcil_excel: 'Reconciling an excel file',
        view_actual_file: 'Views',
        other_file: 'Other file',
        trade_close: 'Close to node',
        Transformation: {
          'amp_short': 'Trans.',
          'amp': 'Modifier la mise en page',
          'amp_import': 'Depuis autre diagramme',
          'amp_manuelle': 'Positionnement',
          'trans_topo': 'Topologiques',
          'fmep': 'Autre diagramme',
          'ad': 'Appliquer',
          'undo': 'Annuler',
          'Shortcuts': 'Raccourcis sélections',
          'unSelectAll': 'Aucun',
          'selectAll': 'Tout',
          'selectDefault': 'Défaut',
          'Topology': 'Ajouts et suppressions',
          'Geometry': 'Tailles et positions',
          'Attribut': 'Attributs',
          'Tags': 'Etiquettes',
          'Values': 'Valeurs des flux',
          'Views': 'Vues',
          'freeLabels': 'Zones de texte',
          'addNode': 'Aj. Noeuds',
          'removeNode': 'Sup. Noeuds',
          'addFlux': 'Aj. Flux',
          'removeFlux': 'Sup. Flux',
          'PosNoeud': 'Noeuds',
          'posFlux': 'Flux',
          'attrNode': 'Noeuds',
          'attrFlux': 'Flux',
          'tagLevel': 'Niveaux de détail',
          'tagNode': 'Noeuds',
          'tagFlux': 'Flux',
          'tagData': 'Données',
          'tagNode_assign': 'Assigner une étiquette',
          'tagFlux_assign': 'Assigner une étiquette',
          'attrGeneral': 'Zone de dessin',
          'title': 'Transformations',
          'disabled_view': 'Impossible to import views into a view, if you want to import them go to the master data',
          'list_icon':'Icon catalog',
          'list_icon_tooltip':'Import icon list used in imported layout to the actual data.'
        },
        tooltips: {
          publish: 'Publish online',
          export: 'Export as image',
          reconcil: 'Reconciliate data',
          tool_afm: 'Use annex tools to reconciliation',
        }
      },
      Noeud: {
        'plns': 'Parameter for selected nodes',
        'img_visibility': 'Image visibility',
        'img_src': 'Source',
        'HL': 'Hyperlink',
        'open_HL': 'Open',
        'illustration': 'Illustration',
        'illustration_type': 'Illustration Type',
        animate:'Launch animation',
        tabs: {
          'icon': 'Icon',
          'fo': 'Illustration',
          'hl': 'Hyperlink'
        },
        apparence: {
          'HideAlone': 'Hide if intermediate',
          'toScale': 'Node out of scale',
          'Orientation': 'Orientation',
        },
        icon: {
          'icon': 'Icon',
          'Visibilité': 'Visibility of icons',
          'si': 'Select icon',
          'couleur': 'Color',
          'rIN': 'Size ratio icon/node',
          'Aucun': 'None',
          'icon_catalog': 'Select an icon from catalog'
        },
        foreign_object: {
          'Visibilité': 'Visibility',
          'raw': 'Raw editor',
          'not_activated': 'Set visibility to activate'
        },
        FO: {
          'FO': 'Text',
          'content': 'Content',
          'submit': 'Submit',
          'cancel': 'Cancel'
        },
      },
      Flux: {
        'asf': 'Apply style to links having this style',
        data: {
          'toPrecision': 'Display the value in scientific notation',
          'fla': 'Display free links',
          'astr': 'Display structure',
        },

      },
      LL: {
        'hl': 'Height',
        'll': 'Width',
        'ft': 'Opacity',
        'cfl': 'Background Color',
        'bt': 'Invisible',
        'hide_border': 'Hide border',
        'display_border': 'Display border',
        'cbl': 'Border Color',
        'labels': 'Labels',
        'title': 'Title'
      },
      view: {
        'unit': 'Unit.',
        'storytelling': 'Storytelling',
        'select': 'Select View',
        'actual': 'Sankey master',
        'name': 'Name',
        'delete': 'Delete',
        'copy': 'Clone',
        'catalog': 'Catalog',
        'import': 'Import',
        'export': 'Download only the current view',
        'applyDisplayFromView': 'Apply the layout from another view',
        'ns': 'View not saved',
        'warn_ns': 'You are about to change your view before saving the current view, do you want to save the changes before changing your view ?',
        'dont_save': 'Don\'t save',
        'save': 'Save the view',
        'exportAll': 'Export all view',
        'importMultiple': 'Import multiple views',
        'unit_node': 'Create a unitary view',
        'to_normal_view': 'Considering this view as non-exploring',
        'in_new': 'in a new vue',
        'in_existing': 'in the view :',
        'template_unitary_zdt_content': 'Unitary Sankey build from :',
        'template_unitary_zdt_content_of_node': 'Unitary node :',
        'keep_master_var': 'Master variable',
        'setTransparentAttr': 'Choose variables value herited from Master',
        'updateViewWithMasterVar': 'Update current view with selected parameter',
        'edit_name': 'Edit name',
        'modify_name_view': 'Modify the name of the view',
        'view_title_modal_select_link_ref_in_unitary_sankey': 'Values displayed',
        'choose_link_ref_sankey_unit': 'Values',
        'chose_io': 'Choose input or output links',
        'chose_node_to_configure': 'Choose a unitary node to configure',
        'chose_link_ref': 'Choose links referentiel to normalize sankey',
        'prefix_copy': 'Copy of',
        'selectNodeForUnitaryView': 'Select a node to create a unitary sankey from',
        'output_link': 'Output',
        'input_link': 'Input',
        legend_unit_sankey_values_links: 'Links value',
        legend_unit_sankey_percent_links: 'Percent compared to the sum of input or output',
        legend_unit_sankey_normalize_links: 'Standardized value by the link :',
        from_actual: 'From current data',
        from_excel: 'From excel file',
        unit_from_excel: 'Unit. from excel',
        select_excel_file: 'Choose an excel file to extract unitary view from',
        select_data_source: 'Select an imported file',
        create_unit: 'Create unitary views',
        create: 'Create unitary view from selected nodes',
        unit_sankey_values_links: 'Display links value',
        unit_sankey_percent_links: 'Display links value as percent',
        unit_sankey_normalize_links: 'Display links value normalized by one or more links value',
        default_unit_view_name: 'Unitary view of node ',

        tooltips: {
          keep_master_var: 'Allow to choose variable value of the sankey view to be from the sankey master',
          catalog_data: 'Generate a catalog of views : gather views from different files',

          PrevViewButton: 'Display previous view',
          NextViewButton: 'Display next view',
          saveView: 'Save the modification of the view in master data',
          home: 'Return to master data (database from which views come from)',
          buttonCreateView: 'Generate a view from actual sankey diagram',
          buttonCloneView: 'Copy the the current view to a new one ',
          buttonImportView: 'Import a data as a view',
          buttonExportView: 'Export a view as a data (without other view)',
          buttonCloneMasterAttrView: 'Tranfer value from master data to the view elements',
          button_delete_actual_view: 'Delete actual view',
          unit_from_excel: 'Excel  Generate views from one or more excel files',
          choose_link_ref_sankey_unit: 'Choisir les valeurs des flux à afficher Choose links values to display'
        }
      },
      toast:{
        v_loaded:'View loaded',
        v_loading:'Loadig new view'
      },
      'useTemplate': 'Use this template',
      'dl': 'Download excel',
      'elements_sankey+_blocked': 'Blocked diagram elements (OpenSankey+)',
      'mfa_blocked': 'Blocked diagram elements (MFASankey)',
      'elements_sankey+_blocked_long': 'Some Sankey elements are not visible because they come from OpenSankey+ and your account does not have this module',
      'elements_mfa_blocked_long': 'Some Sankey elements are not visible because they come from MFASankey and your account does not have this module',
      Tags:{
        sequence:'Sequence',
        sequence_timeout:'Timeout between steps',
        tooltips:{
          sequence:'Activate mode sequence for the datatag group, it make appear a sequential stepper to play tag after of the group'
        }
      },
      // Trad for icons
      'import':{'import':'Imported icon'},
      agricol:{
        'agricol':'agricol',
        'pack-of-cereal':'pack-of-cereal',
        'apple':'apple',
        'apple-2':'apple-2',
        'banana':'banana',
        'banana-2':'banana-2',
        'berry':'berry',
        'berry-2':'berry-2',
        'pear':'pear',
        'pear-2':'pear-2',
        'watermelon':'watermelon',
        'watermelon-2':'watermelon-2',
        'fruits':'fruits',
        'fruits-2':'fruits-2',
        'fruits-3':'fruits-3',
        'pineapple':'pineapple',
        'pineapple-2':'pineapple-2',
        'nut':'nut',
        'nut-2':'nut-2',
        'melon':'melon',
        'melon-2':'melon-2',
        'windmill':'windmill',
        'windmill-2':'windmill-2',
        'tractor':'tractor',
        'tractor-2':'tractor-2',
        'beekeeping':'beekeeping',
        'farmer':'farmer',
        'farmer-2':'farmer-2',
        'gardening':'gardening',
        'bee':'bee',
        'bee2':'bee2',
        'honey':'honey',
        'honey-2':'honey-2',
        'hatch':'hatch',
        'eggs':'eggs',
        'eggs-2':'eggs-2',
        'eggs-3':'eggs-3',
        'grape':'grape',
        'grape-2':'grape-2',
        'tomateo':'tomateo',
        'tomateo-2':'tomateo-2',
        'radish':'radish',
        'carrot':'carrot',
        'carrot-2':'carrot-2',
        'carrot-3':'carrot-3',
        'eggplant':'eggplant',
        'eggplant-2':'eggplant-2',
        'artichoke':'artichoke',
        'artichoke-2':'artichoke-2',
        'potato':'potato',
        'potato-2':'potato-2',
        'potatoes':'potatoes',
        'potatoes-2':'potatoes-2',
        'cabbage':'cabbage',
        'cabbage-2':'cabbage-2',
        'capsicum':'capsicum',
        'capsicum-2':'capsicum-2',
        'barn':'barn',
        'barn-2':'barn-2',
        'farming':'farming',
        'agrologie':'agrologie',
        'fertilizer':'fertilizer',
        'fertilizer-2':'fertilizer-2',
        'garlic':'garlic',
        'garlic-2':'garlic-2',
        'water-droplet':'water-droplet',
        'water-droplet-2':'water-droplet-2',

      },

      meat:{
        'meat':'meat',
        'horse':'horse',
        'horse-head':'horse-head',
        'cow-head':'cow-head',
        'pig-head':'pig-head',
        'rabbit-head':'rabbit-head',
        'sheep-head':'sheep-head',
        'goose-head':'goose-head',
        'chicken-head':'chicken-head',
        'chicken':'chicken',
        'duck-head':'duck-head',
        'turkey-head':'turkey-head',
        'fowl-meat-cooked':'fowl-meat-cooked',
        'meat-2':'meat-2',
        'ribs':'ribs',
        'meat-porc':'meat-porc',
        'ribs-2':'ribs-2',
        'backbone':'backbone',
        'lungs':'lungs',
        'lungs-2':'lungs-2',
        'leather-skin':'leather-skin',
        'leather-skin-2':'leather-skin-2',
        'sausage':'sausage',
        'pig':'pig',
        'pig-2':'pig-2',
        'sheep':'sheep',
        'sheep-2':'sheep-2',
        'rabbit-2':'rabbit-2',
        'rabbit':'rabbit',
        'cow':'cow',
        'cow-2':'cow-2',
        'goose':'goose',
        'goose-2':'goose-2',
        'fish':'fish',
        'fish-2':'fish-2',

      },
      waste:{
        'waste':'waste',
        'bin-truck':'bin-truck',
        'bin-recycle':'bin-recycle',
        'bin-truck-2':'bin-truck-2',
        'incineration':'incineration',
        'trash-non-recyclable':'trash-non-recyclable',
        'trash-recyclable':'trash-recyclable',
        'trash-bio':'trash-bio',
        'trash-glass':'trash-glass',
        'trash-paper':'trash-paper',
        'trash-cardboard':'trash-cardboard',
        'bio-waste':'bio-waste',
        'wood':'wood',
        'scrap-metal':'scrap-metal',
        'rubble':'rubble',
        'placoplatre':'placoplatre',
        'furniture-waste':'furniture-waste',
        'electronic-waste':'electronic-waste',
        'ressource':'ressource',
        'recycle-paper':'recycle-paper',
        'recycle-cardboard':'recycle-cardboard',
        'recycle-plastic':'recycle-plastic',
        'recycle-aluminum':'recycle-aluminum',
        'recycle-food-brick':'recycle-food-brick',
        'recycle-metal':'recycle-metal',
        'recycle-glass':'recycle-glass',
        'compost-dock':'compost-dock',
        'reuse':'reuse',
        'transit-site':'transit-site',
        'sorting-center':'sorting-center',
        'transfer-dock':'transfer-dock',
        'energy':'energy',
        'dust-residue':'dust-residue',
        'clinker':'clinker',
        'compost':'compost',
        'recycle-water':'recycle-water',
        'recycle-electronic':'recycle-electronic',
        'recycling':'recycling',
        'cumbersome':'cumbersome',
        'scrap-metal-2':'scrap-metal-2',
        'trash-bin':'trash-bin',
        'trash-bin-2':'trash-bin-2',
        'recycle-cardboard-2':'recycle-cardboard-2',
        'recycle-paper-2':'recycle-paper-2',
        'recycle-glass-2':'recycle-glass-2',
        'recycle-energy':'recycle-energy',
        'recycle-gear':'recycle-gear',
        'industry':'industry',
        'recycle-aluminum-can':'recycle-aluminum-can',
        'bio-energy':'bio-energy',
        'recycle-glass-bottle':'recycle-glass-bottle',
        'recycle-electronic-2':'recycle-electronic-2',
        'recycle-fabric':'recycle-fabric',

      }
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      'connect': 'Connexion',
      MEP: {
        onValidate: 'Pensez à ouvrir le fichier',
        'onBlurNoEnter': 'quitter la zone d\'édition pour mettre à jour sur la zone de dessin',
        show_image: 'Image',
      },
      Menu: {
        'diagramme': 'Diagrammes',
        'LL': 'Édition d\'objets',
        'view': 'Vues',
        'unit': 'Sankey Unitaire',
        'afm': 'AFM',
        'afm_tools': 'Outils',
        'excel': 'Extraire données',
        'pub': 'Publier',
        'setResolutionPNG': 'Choisissez la résolution désirée pour l\'exportation',
        'sankeyOSPDisabled': 'Paramètre désactivé car vous n\'avez pas OpenSankey+',
        'featureLocked': 'Licence',
        'featureBeta': 'Beta-test',

        'home': 'Maître',
        'addView': 'Ajout',
        'updateView': 'M-à-j',
        'precView': 'Préc.',
        'nextView': 'Suiv.',

        'toBeautify': 'Embellir le fichier JSON',

        'updateFOZdd': 'Mettre à jour sur la zone de dessin',

        'import_icon': 'Sélection d\'une icône',
        'import_icon_from_pack': 'Importer le groupe d\'icône',
        'filter_by_name': 'Filtrer par nom ',

        'presentation_OS': 'OpenSankey et une application web qui permet de réaliser simplement des diagrammes de Sankey.\n\nCelle-ci est accessible gratuitement, et inclut les fonctionnalités simples de création de nœuds, de flux et leur étiquetage afin d\'agréger ou de filtrer leur affichage sur le diagramme.\n\n',
        'presentation_OS_limit_node': 'La création d\'un compte gratuit permet de lever la limitation de 15 nœuds fixée pour un usage sans compte.\n\n',
        'presentation_OSP': 'OpenSankey+ est une licence payante qui permet de débloquer de nouvelles fonctionnalités pour la réalisation de beaux diagrammes de Sankey pour des présentations.\n\n Cette licence inclut des fonctionnalités de mise en forme avancées comme l\'ajout d\'images ou d\'icônes illustratifs pour les noeuds, l\'ajout de gradients de couleurs sur les flux et d\'animations, etc.\n\n Mais surtout, OpenSankey+ permet de générer des diaporamas explicatifs de vos diagrammes de Sankey grâce au mécanisme des "Vues", qui enregistre les états visuel de celui-ci (avec / sans certains filtres activés par exemple) pour simplifier la navigation entre eux.\n\n',
        'presentation_OSS': 'SankeySuite est une licence payante qui permet de débloquer toutes les usages avancés de réalisation et d\'analyse des diagrammes de Sankey.\n\nCette licence inclut les fonctionnalités de réconciliation des données de flux afin de relever des incohérences ou de calculer des flux dont les valeurs ne sont pas accessibles directement.\n\n Elle inclut aussi les fonctionnalités de OpenSankey+.\n\n',
        afm_reconcil_json: 'Réconcilier le diagramme de Sankey actuel',
        afm_reconcil_excel: 'Réconcilier un fichier Excel',
        view_actual_file: 'Vues',
        other_file: 'Autre fichier',
        trade_close: 'Près du noeud',
        Transformation: {
          'disabled_view': 'Impossible d\'importer des vues dans une vue. Pour le faire, positionnez-vous sur le diagramme de Sankey maître.',
          'list_icon':'Catalogue d\'icône',
          'list_icon_tooltip':'Importe la liste des icônes utilisées dans les données importées vers les données actuelles.'
        },

        tooltips: {

          'PrevViewButton': 'Charge la vue précédente',
          'NextViewButton': 'Charge la vue suivante',
          'saveView': 'Sauvegarder localement dans le navigateur (mémoire cache)',
          'home': 'Retourne aux données maître',
          'buttonCreateView': 'Sur les données maîtres: crée une vue / sur une vue: clone la vue',
          'buttonCloneView': 'Copie la vue actuelle en une nouvelle vue indépendante de l\'original ',
          'buttonImportView': 'Importe un diagramme dans cette vue (remplace la vue diagramme actuelle)',
          'buttonExportView': 'Exporte la vue actuelle en tant que donnée de diagramme',
          publish: 'Mettre en ligne',
          export: 'Exporter comme une image',
          reconcil: 'Réconcilier les données',
          tool_afm: 'Utiliser des outils annexes à la réconciliation',
        }
      },
      Noeud: {
        'plns': 'Paramètres pour les noeuds sélectionnés',
        'img_visibility': 'Visibilité de l\'image',
        'img_src': 'Source',
        'HL': 'Hyperlien',
        'open_HL': 'Ouvrir',
        'illustration': 'Illustration',
        'illustration_type': 'Type d\'illustration',
        animate:'Lancer animation',
        tabs: {
          'icon': 'Icône',
          'fo': 'Illustration',
          'hl': 'Hyperlien'
        },
        apparence: {
          'HideAlone': 'Masquer si intermédiaire',
          'toScale': 'Hors échelle',
          'Orientation': 'Orientation',
        },
        icon: {
          'icon': 'Icône',
          'Visibilité': 'Visibilité des icônes',
          'si': 'Sélectionner l\'icône',
          'couleur': 'Couleur',
          'rIN': 'Ratio taille icône/noeud',
          'Aucun': 'Aucun',
          'icon_catalog': 'Sélectionner une icône depuis un catalogue'
        },
        foreign_object: {
          'Visibilité': 'Visibilité',
          'raw': 'Editeur brut',
          'not_activated': 'Pour activer l\'editeur, activer la visibilité'
        },
        FO: {
          'FO': 'Texte',
          'content': 'Contenu',
          'submit': 'Ok',
          'cancel': 'Annuler'
        },
      },
      Flux: {
        'asf': 'Appliquer le Style aux flux',
        data: {
          'toPrecision': 'En notation scientifique',
          'fla': 'Flux nuls indéterminés visibles',
          'astr': 'Affichage structure',
        },
      },
      LL: {
        'hl': 'Hauteur',
        'll': 'Largeur',
        'ft': 'Opacité',
        'cfl': 'Couleur fond',
        'bt': 'Invisible',
        'hide_border': 'Masquer la bordure',
        'display_border': 'Afficher la bordure',
        'cbl': 'Couleur bordure',
        'labels': 'Style police',

        'title': 'Titre'
      },
      view: {
        'unit': 'Unit.',

        'storytelling': 'Gestion des vues',
        'select': 'Sélection Vue',
        'actual': 'Sankey maître',
        'name': 'Nom',
        'delete': 'Suppr.',
        'copy': 'Cloner',
        'catalog': 'Catalog.',
        'import': 'Importer',
        'export': 'Télécharger uniquement la vue actuelle',
        'applyDisplayFromView': 'Appliquer la mise en page d\'une autre vue ',
        'ns': 'Vue non enregistrée',
        'warn_ns': 'Vous êtes sur le point de changer de vue avant d\'avoir sauvegardé la vue actuelle, voulez-vous sauvegarder les changements avant de changer de vue ?',
        'dont_save': 'Ne pas enregistrer',
        'save': 'Enregistrer la vue',
        'exportAll': 'Exporter toutes les vues',
        'importMultiple': 'Importer plusieurs vues',
        'unit_node': 'Créer une vue unitaire',
        'to_normal_view': 'Considerer la vue comme non-explorant',
        'in_new': 'dans une nouvelle vue',
        'in_existing': 'dans la vue :',
        'template_unitary_zdt_content': 'Vue unitaire construite à partir de :',
        'template_unitary_zdt_content_of_node': 'Noeud unitaire :',
        'keep_master_var': 'M.à.j',
        'setTransparentAttr': 'Choisir variables hérité du sankey maître',
        'updateViewWithMasterVar': 'Mettre à jour la vue actuelle avec les paramètres sélectionnés',
        'edit_name': 'Editer le nom',
        'modify_name_view': 'Modifier le nom de la vue',
        'view_title_modal_select_link_ref_in_unitary_sankey': 'Valeurs affichées',
        'choose_link_ref_sankey_unit': 'Valeurs',
        'chose_io': 'Position du/des flux de référence par rapport au noeud unitaire',
        'chose_node_to_configure': 'Choisir un noeud unitaire à configurer',
        'chose_link_ref': 'Choisir le(s) flux de référence',
        'prefix_copy': 'Copie de',
        'selectNodeForUnitaryView': 'Choisissez un noeud pour créer un sankey unitaire',
        'output_link': 'Sortie',
        'input_link': 'Entrée',
        legend_unit_sankey_values_links: 'Valeurs des flux',
        legend_unit_sankey_percent_links: 'Pourcentages par rapport au total en entrée ou en sortie',
        legend_unit_sankey_normalize_links: 'Valeurs normalisées par rapport au flux',
        from_actual: 'Depuis les données actuel',
        from_excel: 'Depuis un fichier excel',
        unit_from_excel: 'Unit. excel',
        select_excel_file: 'Choix du fichier excel à partir duquel extraire des vues unitaires',
        select_data_source: 'Sélectionner un fichier importé',
        create: 'Créer des vues unitaires à partir des noeuds sélectionnés',
        create_unit: 'Générer des vues unitaires',
        unit_sankey_values_links: 'Afficher les valeurs des flux',
        unit_sankey_percent_links: 'Afficher les pourcentages des flux',
        unit_sankey_normalize_links: 'Afficher les valeurs des flux normalisés par rapport à des un/des flux de référence',
        default_unit_view_name: 'Vue unitaire du noeud ',

        tooltips: {
          keep_master_var: 'Permet de choisir des variables qui ont pour valeur ceux du sankey maître',
          catalog_data: 'Générer un catalogue de vues : rassembler des vues issues de fichiers différents',
          PrevViewButton: 'Afficher la vue précédente',
          NextViewButton: 'Afficher la vue suivante',
          saveView: 'Sauvegarder localement dans le navigateur (mémoire cache)',
          home: 'Revenir au Sankey maître (base de données d\'où sont issues les vues)',
          buttonCreateView: 'Générer une vue à partir du diagramme actuel',
          buttonCloneView: 'Copie la vue actuelle en une nouvelle vue indépendante de l\'original ',
          buttonImportView: 'Importe un diagramme dans cette vue (remplace la vue diagramme actuelle)',
          buttonExportView: 'Exporte la vue actuelle en tant que donnée de diagramme',
          buttonCloneMasterAttrView: 'Mettre à jour la vue actuelle en chargeant des éléments du Sankey Maître',
          buttonCreateViewUnitary: 'Générer une vue unitaire en choisissant un noeud du diagramme actuel',
          button_delete_actual_view: 'Supprimer la vue actuelle',
          unit_from_excel: 'Générer une ou plusieurs vue(s) unitaire(s) à partir d\'un ou plusieurs fichier(s) Excel',
          choose_link_ref_sankey_unit: 'Choisir les valeurs des flux à afficher',
        }
      },
      toast:{
        v_loaded:'Vue chargée',
        v_loading:'Chargement de la vue'
      },
      'useTemplate': 'Utiliser ce modèle',
      'dl': 'Télécharger (excel)',
      'elements_sankey+_blocked': 'Elements du diagrame bloqués (OpenSankey+)',
      'elements_mfa_blocked': 'Elements du diagrame bloqués (MFASankey+)',
      'elements_sankey+_blocked_long': 'Certains éléments du Sankey ne sont pas visible car ils proviennent de OpenSankey+ et votre compte ne possède pas ce module',
      'elements_mfa_blocked_long': 'Certains éléments du Sankey ne sont pas visible car ils proviennent de MFASankey et votre compte ne possède pas ce module',
      Tags:{
        sequence:'Séquence',
        sequence_timeout:'Délai entre étapes',
        tooltips:{
          sequence:'Active le mode séquence pour le groupe d\'étiquette de données, cela fait apparaitre un stepper pour afficher les tag du groupe à la suite'
        }
      },
      // Traduction pour le nom des icons
      'import':{'import':'Icônes importées'},
      agricol:{
        'agricol':'agricole',
        'pack-of-cereal':'paquet-de-céréales',
        'apple':'pomme',
        'apple-2':'pomme-2',
        'banana':'banane',
        'banana-2':'banane-2',
        'berry':'fraise',
        'berry-2':'fraise-2',
        'pear':'poire',
        'pear-2':'poire-2',
        'watermelon':'pastèque',
        'watermelon-2':'pastèque-2',
        'fruits':'fruits',
        'fruits-2':'fruits-2',
        'fruits-3':'fruits-3',
        'pineapple':'ananas',
        'pineapple-2':'ananas-2',
        'nut':'noix',
        'nut-2':'noix-2',
        'melon':'melon',
        'melon-2':'melon-2',
        'windmill':'moulin-à-vent',
        'windmill-2':'moulin-à-vent-2',
        'tractor':'tracteur',
        'tractor-2':'tracteur-2',
        'beekeeping':'apiculture',
        'farmer':'fermier',
        'farmer-2':'fermier-2',
        'gardening':'jardinage',
        'bee':'abeille',
        'bee2':'abeille-2',
        'honey':'miel',
        'honey-2':'miel-2',
        'hatch':'éclosion',
        'eggs':'oeufs',
        'eggs-2':'oeufs-2',
        'eggs-3':'oeufs-3',
        'grape':'raisin',
        'grape-2':'raisin-2',
        'tomateo':'tomate',
        'tomateo-2':'tomate-2',
        'radish':'radis',
        'carrot':'carotte',
        'carrot-2':'carotte-2',
        'carrot-3':'carotte-3',
        'eggplant':'aubergine',
        'eggplant-2':'aubergine-2',
        'artichoke':'artichaut',
        'artichoke-2':'artichaut-2',
        'potato':'pomme-de-terre',
        'potato-2':'pomme-de-terre-2',
        'potatoes':'pommes-de-terres',
        'potatoes-2':'pommes-de-terres-2',
        'cabbage':'chou',
        'cabbage-2':'chou-2',
        'capsicum':'poivron',
        'capsicum-2':'poivron-2',
        'barn':'grange',
        'barn-2':'grange-2',
        'farming':'agricole',
        'agrologie':'agrologie',
        'fertilizer':'engrais',
        'fertilizer-2':'engrais-2',
        'garlic':'ail',
        'garlic-2':'ail-2',
        'water-droplet':'gouttes-eau',
        'water-droplet-2':'goutte-eau-2',

      },
      meat:{
        'meat':'viande',
        'horse':'cheval',
        'horse-head':'tête-de-cheval',
        'cow-head':'tête-de-vache',
        'pig-head':'tête-de cochon',
        'rabbit-head':'tête-de-lapin',
        'sheep-head':'tête-de-mouton',
        'goose-head':'tête-oie',
        'chicken-head':'tête-de-poule',
        'chicken':'poulet',
        'duck-head':'tête-de-canard',
        'turkey-head':'tête-de-dinde',
        'fowl-meat-cooked':'cuit-à-la-volaille',
        'meat-2':'viande-2',
        'ribs':'côtes',
        'meat-porc':'viande-porc',
        'ribs-2':'côtes-2',
        'backbone':'colone-vertebrale',
        'lungs':'poumons',
        'lungs-2':'poumons-2',
        'leather-skin':'cuir-peau',
        'leather-skin-2':'cuir-peau-2',
        'sausage':'saucisse',
        'pig':'cochon',
        'pig-2':'cochon-2',
        'sheep':'mouton',
        'sheep-2':'mouton-2',
        'rabbit-2':'lapin-2',
        'rabbit':'lapin',
        'cow':'vache',
        'cow-2':'vache-2',
        'goose':'oie',
        'goose-2':'oie-2',
        'fish':'poisson',
        'fish-2':'poisson-2',

      },
      waste:{
        'waste':'déchet',
        'bin-truck':'camion-poubelle',
        'bin-recycle':'poubelle-recyclage',
        'bin-truck-2':'camion-poubelle-2',
        'incineration':'incinération',
        'trash-non-recyclable':'déchets-non-recyclable',
        'trash-recyclable':'déchets-recyclable',
        'trash-bio':'déchets-bio',
        'trash-glass':'déchets-verre',
        'trash-paper':'déchets-papier',
        'trash-cardboard':'déchets-carton',
        'bio-waste':'biodéchets',
        'wood':'bois',
        'scrap-metal':'ferraille',
        'rubble':'décombre',
        'placoplatre':'placoplatre',
        'furniture-waste':'gaspillage-de-meubles',
        'electronic-waste':'déchets-électroniques',
        'ressource':'ressource',
        'recycle-paper':'papier-recyclé',
        'recycle-cardboard':'carton recyclé',
        'recycle-plastic':'recyclage-plastique',
        'recycle-aluminum':'recyclage-aluminium',
        'recycle-food-brick':'recyclage-food-brick',
        'recycle-metal':'métal recyclé',
        'recycle-glass':'verre recyclé',
        'compost-dock':'station-de-compostage',
        'reuse':'réutilisation',
        'transit-site':'site-de-transit',
        'sorting-center':'centre-de-tri',
        'transfer-dock':'centre-de-transfert',
        'energy':'énergie',
        'dust-residue':'poussière-résidu',
        'clinker':'clinker',
        'compost':'compost',
        'recycle-water':'eau-recyclée',
        'recycle-electronic':'recyclage-électronique',
        'recycling':'recyclage',
        'cumbersome':'encombrant',
        'scrap-metal-2':'ferraille-2',
        'trash-bin':'poubelle-déchets',
        'trash-bin-2':'poubelle-déchets-2',
        'recycle-cardboard-2':'recyclage-carton-2',
        'recycle-paper-2':'recyclage-papier-2',
        'recycle-glass-2':'recyclage-verre-2',
        'recycle-energy':'recyclage-énergie',
        'recycle-gear':'équipement-de-recyclage',
        'industry':'industrie',
        'recycle-aluminum-can':'recyclage-cannette-aluminium',
        'bio-energy':'bioénergie',
        'recycle-glass-bottle':'recyclage-bouteille-verre',
        'recycle-electronic-2':'recyclage-électronique-2',
        'recycle-fabric':'recyclage-tissue',
      }
    },
  }
}
import { defineStyle } from '@chakra-ui/react'

export const toolbar_button_4 = defineStyle({
  bgColor: 'primaire.4',
  borderColor: 'secondaire.4',
  _hover: {
    bgColor: 'tertiaire.4',
    borderColor: 'secondaire.4',
  },
})

export const button_dataTagg_sequence_play = defineStyle({
  bgColor: 'primaire.3',
  borderColor: 'primaire.3',
  margin:'0',
  _hover: {
    bgColor: 'secondaire.3',
    borderColor: 'secondaire.3',
  },
})

export const button_dataTagg_sequence_pause = defineStyle({
  bgColor: 'white',
  color:'primaire.3',
  borderColor: 'secondaire.3',
  margin:'0',
  _hover: {
    bgColor: 'secondaire.3',
    borderColor: 'secondaire.3',
  },
})

export const button_dataTagg_sequence_menu_play = defineStyle({
  width:'0.5rem',
  padding:'0px',
  margin:'0px',
  bgColor: 'primaire.3',
  borderColor: 'primaire.3',
  _hover: {
    bgColor: 'secondaire.3',
    borderColor: 'secondaire.3',
  },
})

export const button_dataTagg_sequence_menu_pause = defineStyle({
  width:'0.5rem',
  padding:'0px',
  margin:'0px',
  bgColor: 'white',
  color:'primaire.3',
  borderColor: 'secondaire.3',
  _hover: {
    bgColor: 'secondaire.3',
    borderColor: 'secondaire.3',
  },
})
export const resources_guided_tour = {
  //=======================================================
  //EN
  //=======================================================
  en: {
    translation: {
      guide: {
        guide: 'Guided tour',
        toolbar: 'The tools bar. Edition, selection and filtering tools that allows you to quickly interact with your Sankey diagram.',
        drawing_area: 'The drawing area. From there you can directly draw and interact with your Sankey diagram with the mouse.',
        nav_menu: 'The navigation menu. You will find there all functionalities for your project as loading, downloading, settings and helps.',
        save_in_cache: 'This button allow you to save your progress in your browser\'s cache so you can exit the app and return later and continue your current diagram.',
        menu_config: 'The configuration menu. Set everything on your Sankey diagram from here, such as data values, names, and many more options.',
        tutorials_button: 'You will find some useful tutorials here that will explain to you everything you need to know to master OpenSankey',
        settings_button: 'You can select more advanced menus and options from here.'
      },
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      guide: {
        guide: 'Visite guidée',
        toolbar: 'La barre d\'outils. Outils d\'édition, de sélection et de filtrage qui vous permettent d\'interagir rapidement avec votre diagramme Sankey.',
        drawing_area: 'La zone de dessin. A partir de là, vous pouvez directement dessiner et interagir avec votre diagramme Sankey à l\'aide de la souris.',
        nav_menu: 'Le menu de navigation. Vous y trouverez toutes les fonctionnalités de votre projet comme le chargement, le téléchargement, les réglages et l\'aide.',
        save_in_cache: 'Ce bouton vous permet de sauvegarder votre progression dans le cache de votre navigateur. Ainsi si vous quittez l\'application vous pourrez revenir plus tard et continuer votre projet à partir de cette sauvegarde.',
        menu_config: 'Le menu de configuration. C\'est à partir d\'ici que vous pouvez tout paramétrer sur votre diagramme Sankey, comme les valeurs des données, les noms, et bien d\'autres options encore',
        tutorials_button: 'Vous trouverez ici des tutoriels utiles qui vous expliqueront tout ce que vous devez savoir pour maîtriser OpenSankey.',
        settings_button: 'Vous pouvez sélectionner des menus et des options plus avancés à partir d\'ici.'
      },
    }
  }
}
